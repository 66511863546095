<template>
  <div>
   <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="setConfirmLeaveForm = true">Болих</el-button>
          <el-button type="success" @click="updateBlock('blockForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-loading="loading">
      <el-col :span="20" :offset="2">
        <custom-header :title="'Блок засах'"/>
        <el-form label-position="top" :model="blockForm" :rules="blockRules" ref="blockForm">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Гарчиг /Монгол/">
                        <el-input v-model="blockForm.name_mon" placeholder="Монгол гарчиг оруулах ..."></el-input>
                      </el-form-item>
                    </el-col>
                     <el-col :span="8">
                        <el-form-item label="Гарчиг /Англи/">
                          <el-input v-model="blockForm.name_eng" placeholder="Англи гарчиг оруулах ..."></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Гарчиг байрлал">
                        <el-select v-model="blockForm.title_location " placeholder="Дунд ..." class="block">
                          <el-option
                          v-for="position in titleLocation"
                          :key="position.value"
                          :label="position.label"
                          :value="position.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                   <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Байрлах хуудас" prop="page">
                        <el-input v-model="blockForm.page" placeholder="https://toktokmall.mn"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Төлөв" prop="status">
                        <el-select v-model="blockForm.status" placeholder="Идэвхтэй ..." class="block">
                          <el-option
                          v-for="status in blockStatus"
                          :key="status.value"
                          :label="status.label"
                          :value="status.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Тайлбар /Монгол/">
                        <el-input
                        type="textarea" :rows="2" v-model="blockForm.description_mon" placeholder="Хар баасан гариг ...">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Тайлбар /Англи/">
                        <el-input
                          type="textarea" :rows="2" v-model="blockForm.description_eng" placeholder="Black Friday ...">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Дэвсгэр өнгийн төрөл" >
                        <el-checkbox-group v-model="set_type" @change="onChangeTypeBackground">
                        <el-checkbox label="Өнгө" prop="background"/>
                        <el-checkbox label="Зураг"/>
                      </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Эрэмбэ" prop="sort">
                        <el-input type="number" v-model="blockForm.sort" placeholder="1 ..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="set_type.includes('Өнгө')">
                    <el-col :span="4">
                      <el-color-picker v-model="color"></el-color-picker>
                    </el-col>
                    <el-col :span="20">
                      <div :style="`background-color: ${ color }; width: 150px; height: 35px; border-color: gray; border-width: thin; border-style: solid; border-radius: 5px`"></div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="set_type.includes('Зураг')">
                    <el-col>
                      <el-form-item>
                        <el-upload
                              list-type="picture-card"
                              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                              action=""
                              :auto-upload="false"
                              :file-list="tempImagesBackground"
                              :on-remove="(file) => handleRemove(file, 'background')"
                              :on-change="(file) => getFile(file, 'background')">
                              <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="mt10">
                    <el-col :span="12">
                      <el-form-item label="Эхлэх огноо" prop="start_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          v-model="blockForm.start_date"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Дуусах огноо" v-if="set_end_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="end_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <div>
                      <el-checkbox v-model="set_end_date">Дуусах огноо оруулах</el-checkbox>
                    </div>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class='panel-item'>
                  <el-form :model="deviceForm" :rules="deviceFormRules" ref="deviceForm">
                  <el-row :gutter="20">
                    <el-col>
                      <header>Баганаар /Column/</header>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Гар утас" prop="mobile">
                        <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.mobile"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Таблет" prop="tablet">
                        <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.tablet"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Зөөврийн комьпютер" prop="laptop">
                        <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.laptop"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Жижиг дэлгэцтэй комьпютер" prop="small_desktop">
                        <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.small_desktop"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Том дэлгэцтэй комьпютер" prop="big_desktop">
                        <el-input type="number" placeholder="Тоо ширхэг ..." v-model="deviceForm.big_desktop"></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                      <el-form-item label="Комьпютер" prop="desktop">
                        <el-input placeholder="Тоо ширхэг ..." v-model="desktop"></el-input>
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                  </el-form>
                  <el-row :gutter="20">
                    <el-col>
                       <header>Ямар төхөөрөмж дээр харагдах эсэх?</header>
                    </el-col>
                    <el-col>
                      <el-checkbox v-model="blockForm.mobile_show">Гар утас</el-checkbox>
                      <el-checkbox v-model="blockForm.tablet_show">Таблет</el-checkbox>
                      <el-checkbox v-model="blockForm.web_show">Веб</el-checkbox>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col class="mt20">
                      <header>Хөндлөнгөөр /Row/</header>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item>
                        <el-checkbox v-model="is_campaign">Толгой зурагтай эсэх</el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        <el-checkbox v-model="blockForm.arrow_show">Слайдтай эсэх</el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12" v-if="is_campaign">
                      <el-form-item label="Толгой зураг">
                         <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :auto-upload="false"
                          :file-list="tempImagesHeadLogo"
                          :on-remove="(file) => handleRemove(file, 'headlogo')"
                          :on-change="(file) => getFile(file, 'headlogo')">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Мөрөөр" v-if="blockForm.arrow_show">
                        <el-input v-model="show_rows_count" placeholder="Тоо ширхэг ..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- Right-section -->
            <el-col :span="12">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20" class="mb15">
                    <el-col>
                      <el-button @click="confirmLeaveForm = true" type="primary">Блок нэмэх <i class="el-icon-plus"></i></el-button>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                     <draggable v-model="blockForm.images" group="people" @start="drag=true" @end="drag=false">
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" v-for="(image, index) in blockForm.images" :key="index" class="mt10">
                        <el-card :body-style="{ padding: '0px' }" shadow="hover">
                          <el-image :src="image.image" class="image" :fit="'contain'"></el-image>
                          <div style="padding: 14px;">
                            <div>
                               <a :href="image.url" target="_blank">
                                <span>{{image.url}}</span>
                              </a>
                            </div>
                            <div class="bottom clearfix text-right">
                              <el-button type="text" @click="deleteBlockItems(index)">Устгах</el-button>
                            </div>
                          </div>
                        </el-card>
                      </el-col>
                    </draggable>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-button type="danger" @click="confirmBannerDialog = true">Устгах</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="default" @click="setConfirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="updateBlock('blockForm')">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- CreateBlockDialog -->
    <el-dialog title="Блок үүсгэх" width="30%" :visible.sync="confirmLeaveForm">
      <div class="ml20 mr20">
        <el-row :gutter="20">
          <el-form label-position="top" :rules="blockItemRules" :model="blockItemForm" ref="blockItemForm">
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="Зураг">
                  <el-upload
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :limit="1"
                    :file-list="tempImagesImage"
                    :auto-upload="false"
                    :on-remove="(file) => handleRemove(file, 'image')"
                    :on-change="(file) => getFileBlockImage(file, 'image')">
                    <span v-if="this.firstBlockImageSize !== null">{{this.firstBlockImageSize.width}}x{{this.firstBlockImageSize.height}}</span>
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="Шилжих хуудас" prop="url">
                  <el-input v-model="blockItemForm.url" placeholder="https://toktokmall.mn"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false">Болих</el-button>
        <el-button type="success" @click="createBlockItems('blockItemForm')">
          Үүсгэх
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="setConfirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="setConfirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/block-list">
          <el-button type="danger" @click="setConfirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <el-dialog
      title="Блок устгах"
      :visible.sync="confirmBannerDialog"
      width="50%"
    >
      <div>
        <strong>{{blockForm.name_mon}}</strong> блокыг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmBannerDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteBlock(blockForm)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="Блок устгах"
      :visible.sync="confirmBlockItemDialog"
      :data="blockForm.images"
      width="50%"
    >
      <div>
        {{blockForm.images}}
        <strong>'{{ blockForm.images.url }}'</strong> линктэй блокыг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmBlockItemDialog = false" class="mr10"
          >Болих
          </el-button
        >
        <el-button type="danger" @click="deleteBlockItems(index)">
          Устгах
        </el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { handlers } from '../../../helpers/custom'
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
export default {
  name: 'BlockAddForm',
  components: {
    CustomHeader,
    draggable
  },

  data () {
    return {
      show_rows_count: '',
      end_date: '',
      head_logo: '',
      firstBlockImageSize: null,
      confirmBannerDialog: false,
      confirmBlockItemDialog: false,
      loading: false,
      uploadingBackground: false,
      uploadingHeadLogo: false,
      uploadingImage: false,
      tempImagesImage: [],
      tempImagesBackground: [],
      tempImagesHeadLogo: [],
      confirmLeaveForm: false,
      setConfirmLeaveForm: false,
      set_type: ['Өнгө'],
      set_end_date: false,
      is_campaign: false,
      color: '#409EFF',
      blockItemForm: {
        url: '',
        // title_mon: '',
        // image_sort: '',
        image: ''
      },
      deviceForm: {
        big_desktop: '',
        small_desktop: '',
        mobile: '',
        tablet: '',
        laptop: ''
      },
      blockForm: {
        title_location: '',
        status: '',
        arrow_show: false,
        mobile_show: false,
        tablet_show: false,
        web_show: false,
        background_type: '',
        background: '',
        sort: '',
        page: '',
        description_mon: '',
        description_eng: '',
        name_mon: '',
        name_eng: '',
        start_date: new Date(),
        images: [],
        column: []
      },

      blockStatus: [{
        value: 'active',
        label: 'Идэвхтэй'
      }, {
        value: 'expired',
        label: 'Идэвхгүй'
      }],

      titleLocation: [{
        value: 'left',
        label: 'Зүүн'
      }, {
        value: 'center',
        label: 'Дунд'
      }, {
        value: 'right',
        label: 'Баруун'
      }],

      blockItemRules: {
        url: [
          { required: true, message: 'Шилжих хуудсаа оруулна уу', trigger: 'blur' }
        ],
        title_mon: [
          { required: true, message: 'Блокны нэрээ оруулна уу', trigger: 'blur' }
        ],
        image_sort: [
          { required: true, message: 'Эрэмбэ оруулна уу', trigger: 'blur' }
        ]
      },

      deviceFormRules: {
        mobile: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        tablet: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        laptop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        big_desktop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        small_desktop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ]
      },

      blockRules: {
        show_row: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        name_mon: [
          { required: true, message: 'Блокны монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        page: [
          { required: true, message: 'Шилжих хуудсаа оруулна уу', trigger: 'blur' }
        ],
        description_mon: [
          { required: true, message: 'Тайлбараа оруулна уу', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: 'Эрэмбэ оруулна уу', trigger: 'blur' }
        ],
        status: [
          { required: true, message: 'Төлөв заавал сонгоно уу', trigger: 'blur' }
        ],
        start_date: [
          { required: true, message: 'Эхлэх хугацаа оруулна уу', trigger: 'blur' }
        ],
        background: [
          { required: true, message: 'Заавал сонгоно уу?', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        tablet: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        laptop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        big_desktop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ],
        small_desktop: [
          { required: true, message: 'Тоогоо оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    if (this.$route.params.id) {
      this.getOneBlock(this.$route.params.id)
    }
  },

  methods: {
    getOneBlock (id) {
      services.getOneBlock(id).then(response => {
        this.loading = true
        if (response.status === 'success') {
          this.blockForm = response.data
          if (response.data && response.data.column) {
            response.data.column.forEach(el => {
              this.deviceForm.mobile = el.mobile
              this.deviceForm.tablet = el.tablet
              this.deviceForm.laptop = el.laptop
              this.deviceForm.big_desktop = el.big_desktop
              this.deviceForm.small_desktop = el.small_desktop
            })
          }
          if (response.data.background.length === 7 && response.data.background.includes('#')) {
            this.set_type = ['Өнгө']
            this.color = response.data.background
          }
          if (response.data.background.length > 7) {
            this.set_type = ['Зураг']
            this.blockForm.background = response.data.background
          }
          if (Object.prototype.hasOwnProperty.call(response.data, 'end_date') && response.data.end_date !== '') {
            this.set_end_date = true
            this.end_date = response.data.end_date
          }
          if (response.data.head_logo !== '') {
            this.is_campaign = true
          }
          if (response.data.column !== []) {
            this.firstBlockImageSize = response.data.image_size
          }
          if (response.data.show_rows_count !== '') {
            this.show_rows_count = response.data.show_rows_count
          }
          this.setBackgroundImages(this.blockForm.background)
          this.setHeadLogoImages(this.blockForm.head_logo)
          this.loading = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
          this.loading = false
        }
      })
    },

    updateBlock (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tempImagesHeadLogo.forEach(element => {
            this.head_logo = element.url
          })
          if (this.set_type.includes('Зураг')) {
            this.blockForm.background_type = 'image'
            this.tempImagesBackground.forEach(element => {
              this.blockForm.background = element.url
            })
          } else if (this.set_type.includes('Өнгө')) {
            this.blockForm.background_type = 'color'
            this.blockForm.background = this.color
          }
          var tempFormData = JSON.parse(JSON.stringify(this.blockForm))
          tempFormData.column = [
            {
              big_desktop: parseInt(this.deviceForm.big_desktop),
              laptop: parseInt(this.deviceForm.laptop),
              mobile: parseInt(this.deviceForm.mobile),
              small_desktop: parseInt(this.deviceForm.small_desktop),
              tablet: parseInt(this.deviceForm.tablet)
            }
          ]
          if (this.end_date !== '') {
            tempFormData.end_date = this.end_date
          }
          if (this.head_logo !== '') {
            tempFormData.head_logo = this.head_logo
          }
          if (this.show_rows_count !== '') {
            tempFormData.show_rows_count = parseInt(this.show_rows_count)
          }
          if (this.blockForm.sort !== '') {
            tempFormData.sort = parseInt(this.blockForm.sort)
          }
          if (this.is_campaign === true) {
            tempFormData.is_campaign = true
          }
          services.updateBlock(tempFormData).then(response => {
            this.loading = true
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', response.message, 'success')
              this.loading = false
              this.$router.push({ name: 'blockList' })
            } else if (response.status === 'unsuccess') {
              this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
              this.loading = false
            } else {
              this.loading = false
            }
          })
        }
        this.loading = false
      })
    },

    setHeadLogoImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImagesHeadLogo = tempImages
    },

    setBackgroundImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImagesBackground = tempImages
    },

    createBlockItems (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tempImagesImage.forEach(element => {
            this.blockItemForm.image = element.url
          })
          this.blockForm.images.push(
            {
              url: this.blockItemForm.url,
              image: this.blockItemForm.image,
              title_mon: this.blockItemForm.title_mon,
              image_sort: this.blockItemForm.image_sort
            }
          )
          this.tempImagesImage = []
          this.blockItemForm.url = ''
          this.blockItemForm.title_mon = ''
          this.blockItemForm.image_sort = ''
          this.confirmLeaveForm = false
        }
      })
    },

    deleteBlockItems (comingIndex) {
      const blockItems = JSON.parse(JSON.stringify(this.blockForm.images))
      const tempItems = []
      blockItems.forEach((element, index) => {
        if (index !== comingIndex) {
          tempItems.push(element)
        }
      })
      this.blockForm.images = tempItems
    },

    deleteBlock (body) {
      this.loading = true
      services.deleteBlock(body.super_block_id).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${body.name_mon} нэртэй блок устлаа`, 'success')
          this.$router.push({
            name: 'blockList'
          })
          this.loading = false
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${body.name_mon} нэртэй блокыг устгахад алдаа гарлаа`, 'error')
          this.loading = false
        }
      })
    },

    onChangeTypeBackground (type) {
      if (type.length === 2) {
        const tempType = JSON.parse(JSON.stringify(this.set_type))
        tempType.splice(0, 1)
        this.set_type = tempType
      }
    },

    async setFirstImageSize (file) {
      const self = this
      const promise = new Promise((resolve) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file.raw)
        img.onload = function () {
          var tempSize = { width: 0, height: 0 }
          tempSize.width = img.width
          tempSize.height = img.height
          self.firstBlockImageSize = tempSize
          resolve()
        }
      })
      await promise
    },

    async getFileBlockImage (file, type) {
      let isReady = true
      if (!this.firstBlockImageSize) {
        await this.setFirstImageSize(file)
        isReady = false
      }
      handlers.checkImageSize(file, isReady, this.firstBlockImageSize ? this.firstBlockImageSize.width : 0, this.firstBlockImageSize ? this.firstBlockImageSize.height : 0).then(() => {
        this.uploadingImage = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.uploadingImage = false
            if (response.status === 'success') {
              this.tempImagesImage.push({
                url: response.url
              })
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    getFile (file, type) {
      handlers.checkImageSize(file, false).then(() => {
        if (type === 'background') {
          this.uploadingBackground = true
        } else if (type === 'headlogo') {
          this.uploadingHeadLogo = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'background') {
              this.uploadingBackground = false
            } else if (type === 'headlogo') {
              this.uploadingHeadLogo = false
            }
            if (response.status === 'success') {
              if (type === 'background') {
                this.tempImagesBackground.push({
                  url: response.url
                })
              } else if (type === 'headlogo') {
                this.tempImagesHeadLogo.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    handleRemove (file, type) {
      var tempId = 0
      if (type === 'background') {
        this.tempImagesBackground.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesBackground.splice(tempId, 1)
      } else if (type === 'headlogo') {
        this.tempImagesHeadLogo.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesHeadLogo.splice(tempId, 1)
      } else if (type === 'image') {
        this.tempImagesImage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesImage.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
